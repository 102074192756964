import React from 'react';
import './contact.css';

const Contact = () => {
 
    return(
        <>
            <h3 className="testContacthead">Contact page</h3> 
        </>
    )
}
export default Contact;