import React from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import './overview.css';

const Overview = () => {
    
    /*setup id for useParams to take id from link */
    const { id } = useParams();
    const item = useSelector((state) => state.items.items.find((item) => item.id === parseInt(id)));
    console.log("I found item:",item); 

    return(
        <>
            <div className='overview_container'>
                <div className='overview'>   
                <h1>Overview Page</h1>
                <p>type: {item.type}</p> 
                {/* Assuming you have content sections with IDs for each property */}
                <section id="title">
                    <h2>{item.title}</h2>
                    <img src={item.img} />
                    <p>{item.overview}</p>
                    {/* Content for title section */}
                </section>
                <section id="design">
                    <h2>{item.design}</h2>
                    <img src={item.design_img} />
                     <p>{item.design_descript}</p>
                    {/* Content for design section */}
                </section>
                <section id="features">
                    <h2>{item.features}</h2>
                    <p>{item.features_descript}</p>
                    {/* Content for features section */}
                </section>
                <section id="technology">
                    <h2>{item.technology}</h2>
                    <p>{item.tech_descript}</p>
                    {/* Content for technology section */}
                </section>
            </div>
            </div> 
        </>
    )
}
export default Overview;