import React from 'react';
import './login.css';

const Login = () => {
 
    return(
        <>
            <h3 className="testLoginhead">Login page</h3> 
        </>
    )
}
export default Login;