import React from 'react';
import './service.css';

const Service = () => {
 
    return(
        <>
            <h3 className="testServicehead">Service page</h3> 
        </>
    )
}
export default Service;