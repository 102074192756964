import React from 'react';
import './about.css';

const About = () => {
 
    return(
        <>
        <div>
            <h4 className="testabouthead">About page</h4> 
        </div>
        </>
    )
}
export default About;